import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { DateTimePicker } from "@/components/ui/data-time-picker/date-time-picker";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { toast } from "@/components/ui/use-toast";
import useTimezone from "@/hooks/useTimezone";
import { getCalendarDateTime, getDateTimeStamp } from "@/lib/format";
import { addStat } from "@/lib/stats";
import { cn } from "@/lib/utils";
import { MessageContext } from "@/providers/message";
import { CalendarDateTime } from "@internationalized/date";
import { ColumnDef } from "@tanstack/react-table";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { CalendarIcon, Plus } from "lucide-react";
import { FormEvent, Fragment, useContext, useMemo, useState } from "react";
import HeartZoneRangeInput from "./inputs/heart-zone-range";
import TimezoneInput from "./inputs/timezone";

type Props<T> = {
  columns: (ColumnDef<T> & ColumnProps)[];
  tableName: StatsTable;
  buttonTestId?: string;
};

export default function Add<T>({ columns, tableName, buttonTestId }: Props<T>) {
  const [type, setType] = useState<ValueType>("manual");
  const [modal, setModal] = useState(false);
  const defaultValue = columns.reduce<T>(
    (prev, { accessorKey, defaultValue }) => ({
      ...prev,
      [accessorKey]: defaultValue,
    }),
    {} as T
  );
  const { user } = useContext(MessageContext);
  const [details, setDetails] = useState<T>(defaultValue);
  const queryClient = useQueryClient();
  const timeZone = useTimezone();
  const { isPending, mutate } = useMutation({
    mutationKey: ["stats", tableName, user!.id],
    mutationFn: ({ details }: { details: Partial<T> }) =>
      addStat<T>(details, tableName, user!.id, timeZone),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["stats", tableName, user!.id],
      });
      setModal(false);
      setDetails(defaultValue);
      setType("manual");
    },
    onError: (err) =>
      toast({
        title: "Something went wrong!",
        description: err.message,
        variant: "destructive",
      }),
  });

  async function handleSave(e: FormEvent) {
    e.preventDefault();
    const firstInvalid = columns.find(
      (item) => item.required && !details[item.accessorKey as keyof T]
    );
    if (firstInvalid) {
      toast({
        title: "Missing required properties!",
        description: `The required property "${firstInvalid.accessorKey}" is either empty or missing`,
        variant: "destructive",
      });
      return;
    }
    const validDetails = Object.fromEntries(
      Object.entries(details as any).filter(
        ([k, v]) =>
          columns.find((item) => item.accessorKey === k)?.required || v !== ""
      )
    );
    console.log("input", validDetails);
    mutate({ details: validDetails as Partial<T> });
  }

  const from = details["from" as keyof Omit<T, "id">] as Date;
  const to = details["to" as keyof Omit<T, "id">] as Date;

  const isWrong: boolean | null = useMemo(() => {
    if (!from || !to) return null;
    if (tableName !== "weekly_plans") return null;
    const isFromMonday = from.getDay() === 1;
    const isToCorrectDay =
      (to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24) === 6;
    return !(isFromMonday && isToCorrectDay);
  }, [tableName, details]);

  return (
    <Dialog open={modal} onOpenChange={(is) => setModal(is)}>
      <DialogTrigger asChild>
        <Button
          className="gap-2 h-8"
          size="sm"
          variant="ghost"
          data-testid={buttonTestId}
        >
          <Plus size={14} />
          New
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add {tableName}</DialogTitle>
          <DialogDescription>
            Add new records to the {tableName} for{" "}
            {user?.username || `${user?.first_name} ${user?.last_name || ""}`}
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSave}>
          <div className="grid grid-cols-[max-content_1fr] gap-4 items-center my-4">
            {tableName === "properties" && (
              <Fragment>
                <Label className="text-right">Type</Label>
                <Select
                  value={type}
                  onValueChange={(value) => setType(value as ValueType)}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="manual">Manual</SelectItem>
                    <SelectItem value="timezone">Timezone</SelectItem>
                    <SelectItem value="default-zone2-target">
                      Default zone2 target
                    </SelectItem>
                  </SelectContent>
                </Select>
              </Fragment>
            )}
            {tableName === "properties" && type !== "manual" ? (
              type === "timezone" ? (
                <TimezoneInput
                  value={String(details["value" as keyof Omit<T, "id">])}
                  onValueChange={(value) =>
                    setDetails((prev: any) => ({
                      ...prev,
                      value,
                      name: type,
                    }))
                  }
                />
              ) : (
                type === "default-zone2-target" && (
                  <Fragment>
                    <Label className="text-right">Value</Label>
                    <Input
                      value={details["value" as keyof Omit<T, "id">] as string}
                      placeholder="e.g. 40 meaning 40 minutes"
                      onChange={(e) =>
                        setDetails((prev: any) => ({
                          ...prev,
                          value: e.target.value.replace(/\D/g, ""),
                          name: "default_zone2_target",
                        }))
                      }
                    />
                  </Fragment>
                )
              )
            ) : tableName === "weekly_plans" ? (
              <>
                {isWrong === true && (
                  <Label className="text-danger text-sm col-[2/3] -mb-2">
                    Select a week period (Mon - Sun)
                  </Label>
                )}
                <Label className="text-right" key={`label:Date`}>
                  From
                </Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      id="date"
                      variant={"outline"}
                      className={cn(
                        "justify-start text-left font-normal",
                        !details["from" as keyof Omit<T, "id">] &&
                          !details["to" as keyof Omit<T, "id">] &&
                          "text-muted-foreground",
                        isWrong &&
                          "text-danger border border-danger hover:text-danger"
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {details["from" as keyof Omit<T, "id">] ? (
                        details["to" as keyof Omit<T, "id">] ? (
                          <>
                            {format(
                              details["from" as keyof Omit<T, "id">] as Date,
                              "LLL dd, y"
                            )}{" "}
                            -{" "}
                            {format(
                              details["to" as keyof Omit<T, "id">] as Date,
                              "LLL dd, y"
                            )}
                          </>
                        ) : (
                          format(
                            details["from" as keyof Omit<T, "id">] as Date,
                            "LLL dd, y"
                          )
                        )
                      ) : (
                        <span>Pick a date</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      initialFocus
                      mode="range"
                      selected={
                        !details["from" as keyof Omit<T, "id">] &&
                        !details["to" as keyof Omit<T, "id">]
                          ? undefined
                          : {
                              from: details[
                                "from" as keyof Omit<T, "id">
                              ] as Date,
                              to: details["to" as keyof Omit<T, "id">] as Date,
                            }
                      }
                      onSelect={(value) => {
                        setDetails((prev: any) => ({
                          ...prev,
                          from: value?.from,
                          to: value?.to,
                        }));
                      }}
                      numberOfMonths={2}
                    />
                  </PopoverContent>
                </Popover>
                {Object.keys(defaultValue as any)
                  .filter((i) => !["from", "to"].includes(i))
                  .map((key) => (
                    <Fragment key={key}>
                      <Label className="text-right" key={`label:${key}`}>
                        {key.charAt(0).toUpperCase() + key.substring(1)}
                      </Label>
                      <Input
                        value={String(details[key as keyof Omit<T, "id">])}
                        placeholder={
                          columns.find((item) => item.accessorKey === key)
                            ?.placeholder || ""
                        }
                        onChange={(e) =>
                          setDetails((prev: any) => ({
                            ...prev,
                            [key]: e.target.value,
                          }))
                        }
                        key={`input:${key}`}
                      />
                    </Fragment>
                  ))}
              </>
            ) : tableName === "cardio_records" ? (
              Object.keys(defaultValue as any).map((key) => (
                <Fragment key={key}>
                  <Label className="text-right" key={`label:${key}`}>
                    {key.charAt(0).toUpperCase() + key.substring(1)}
                  </Label>

                  {key === "start_time" ? (
                    <DateTimePicker
                      granularity={"minute"}
                      onChange={(date) => {
                        console.log({ date });
                        setDetails((prev: any) => ({
                          ...prev,
                          start_time: getDateTimeStamp(
                            date as CalendarDateTime
                          ),
                        }));
                      }}
                      value={
                        details[key as keyof Omit<T, "id">]
                          ? getCalendarDateTime(
                              details[key as keyof Omit<T, "id">] as string
                            )
                          : null
                      }
                    />
                  ) : (
                    <Input
                      value={String(details[key as keyof Omit<T, "id">])}
                      placeholder={
                        columns.find((item) => item.accessorKey === key)
                          ?.placeholder || ""
                      }
                      onChange={(e) =>
                        setDetails((prev: any) => ({
                          ...prev,
                          [key]: e.target.value,
                        }))
                      }
                      key={`input:${key}`}
                    />
                  )}
                </Fragment>
              ))
            ) : tableName === "heart_zone_ranges" ? (
              <HeartZoneRangeInput
                values={details as HeartZoneRange}
                onChange={(key, value) =>
                  setDetails((prev) => ({ ...prev, [key]: value }))
                }
              />
            ) : (
              <Fragment>
                {Object.keys(defaultValue as any).map((key) => (
                  <Fragment key={key}>
                    <Label className="text-right" key={`label:${key}`}>
                      {key.charAt(0).toUpperCase() + key.substring(1)}
                    </Label>
                    <Input
                      value={String(details[key as keyof Omit<T, "id">])}
                      onChange={(e) =>
                        setDetails((prev: any) => ({
                          ...prev,
                          [key]: e.target.value,
                        }))
                      }
                      key={`input:${key}`}
                    />
                  </Fragment>
                ))}
              </Fragment>
            )}
          </div>
          <DialogFooter>
            <Button disabled={isPending || isWrong === true} type="submit">
              Save
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
