import { convertToPropertiesTimezone } from "../format";
import { supabase } from "../supabase";
import { toDateString } from "../utils";

export async function getStats(table: StatsTable, userId?: string) {
  if (!userId) {
    throw new Error("No user has been selected!");
  }
  const { data: results, error } = await supabase
    .from(table)
    .select("*")
    .eq("user_id", userId);

  if (error) {
    throw new Error(error.message);
  }

  return results;
}

export async function addStat<T>(
  data: Partial<T>,
  table: StatsTable,
  user_id?: string,
  timezone?: string,
) {
  console.log(data);
  if (!user_id) {
    throw new Error("No user has been selected!");
  }
  const body = {
    ...data,
    user_id,
    ...(table === "cardio_records" && {
      start_time: convertToPropertiesTimezone(
        (data as unknown as CardioRecord)["start_time"],
        timezone,
      ),
    }),
    ...(table === "weekly_plans" && {
      from: toDateString((data as unknown as WeeklyPlan)["from"]),
      to: toDateString((data as unknown as WeeklyPlan)["to"]),
    }),
  };
  const { error } = await supabase.from(table).insert(body).select().single();

  if (error) {
    throw new Error(error.message);
  }
}

export async function deleteStat(id: string, table: StatsTable) {
  const { error } = await supabase.from(table).delete().eq("id", id);
  if (error) {
    return {
      error,
      results: [],
    };
  }

  return {
    results: [],
  };
}

export async function editStat(
  id: string,
  table: StatsTable,
  updated: CardioRecord | WeeklyPlan | UserProperty,
) {
  const { error } = await supabase.from(table).update(updated).eq("id", id);
  if (error) {
    throw new Error(error.message);
  }
}
