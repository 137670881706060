import { Button } from "@/components/ui/button";
import Ring from "@/components/ui/ring";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { toast } from "@/components/ui/use-toast";
import useStats from "@/hooks/useStats";
import useTimezone from "@/hooks/useTimezone";
import { parseDate, toDateString } from "@/lib/utils";
import { MessageContext } from "@/providers/message";
import { useContext, useState } from "react";

type Week = "current" | "last";

const options = [
  {
    value: "current",
    label: "This week",
  },
  {
    value: "last",
    label: "Last week",
  },
];

const parseTime = (interval: string) => {
  const [hours, minutes, seconds] = interval.split(":").map(Number);
  const time = hours * 60 + minutes + Math.round(seconds / 60);
  return time;
};

const constructJSON = (
  weeklyPlan: WeeklyPlan,
  cardioRecords: CardioRecord[],
  options?: { timeZone?: string }
): WeeklyGraphInput => {
  const { from, to, zone2_target, hiit_target } = weeklyPlan;
  const [targetZone2Hours, targetZone2Minutes, targetZone2Seconds] =
    zone2_target.split(":").map(Number);
  const targetZone2Time =
    targetZone2Hours * 60 +
    targetZone2Minutes +
    Math.round(targetZone2Seconds / 60);
  const [targetHiitHours, targetHiitMinutes, targetHiitSeconds] = hiit_target
    .split(":")
    .map(Number);
  const targetHiitTime =
    targetHiitHours * 60 +
    targetHiitMinutes +
    Math.round(targetHiitSeconds / 60);
  cardioRecords.sort(
    ({ start_time: aDate }, { start_time: bDate }) =>
      new Date(aDate).getTime() - new Date(bDate).getTime()
  );
  const records: GraphDate[] = cardioRecords.flatMap(
    ({ start_time, zone2_duration, zone5_duration }) => {
      const startTime = new Date(start_time);
      const zone2Time = parseTime(zone2_duration);
      const hiitTime = parseTime(zone5_duration);
      const date = toDateString(startTime);
      return [
        ...(zone2Time > 0
          ? [
              {
                date,
                planned: 0,
                completed: zone2Time,
                stage: "zone2",
              } as GraphDate,
            ]
          : []),
        ...(hiitTime > 0
          ? [
              {
                date,
                planned: 0,
                completed: hiitTime,
                stage: "hiit",
              } as GraphDate,
            ]
          : []),
      ];
    }
  );

  const firstZone2 = records.findIndex(({ stage }) => stage === "zone2");

  const zone2Exists = firstZone2 !== -1;

  if (zone2Exists) {
    records[firstZone2].planned = targetZone2Time;
  } else {
    records.unshift({
      date: toDateString(new Date(cardioRecords[0].start_time)),
      stage: "zone2",
      planned: targetZone2Time,
      completed: 0,
    });
  }

  const firstHiit = records.findIndex(({ stage }) => stage === "hiit");

  const hiitExists = firstHiit !== -1;

  if (hiitExists) {
    records[firstHiit].planned = targetHiitTime;
  } else {
    records.unshift({
      date: toDateString(new Date(cardioRecords[0].start_time)),
      stage: "hiit",
      planned: targetHiitTime,
      completed: 0,
    });
  }

  console.log({ records });
  return {
    from,
    to,
    records,
    timeZone: options?.timeZone,
  };
};

type Props = {
  isLoading: boolean;
  handleGeneration: (json: WeeklyGraphInput) => Promise<void>;
};

export default function MetricsGraph({ isLoading, handleGeneration }: Props) {
  const [week, setWeek] = useState<Week>("current");
  const { user } = useContext(MessageContext);
  const { cardio_records, weekly_plans } = useStats(user);
  const timeZone = useTimezone();

  console.log(weekly_plans);

  async function generatePreview() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const startOfWeek = new Date(today);
    const currentDayOfWeek = today.getDay();
    const startDiff =
      week === "current"
        ? today.getDate() - currentDayOfWeek + (currentDayOfWeek === 0 ? -6 : 1)
        : today.getDate() - currentDayOfWeek - 6;
    startOfWeek.setDate(startDiff);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    console.log({ startOfWeek, endOfWeek });
    const weeklyPlan = weekly_plans.find(({ from, to }) => {
      if (!from || !to) return false;
      const startDate = parseDate(from);
      const endDate = parseDate(to);
      console.log({ startDate, endDate });
      return startDate >= startOfWeek && endDate <= endOfWeek;
    });
    console.log({ weeklyPlan });
    if (!weeklyPlan) {
      toast({
        title: "No weekly plan found!",
        description: `${
          week.charAt(0).toUpperCase() + week.substring(1)
        } week plan has not been found`,
        variant: "destructive",
      });
      return;
    }
    const cardioRecords = cardio_records.filter(({ start_time }) => {
      const startTime = new Date(start_time);
      return startTime >= startOfWeek && startTime <= endOfWeek;
    });
    console.log({ weeklyPlan, cardioRecords });
    if (cardioRecords.length === 0) {
      toast({
        title: "No cardio records found!",
        description: `Cardio records have not been found for the given week`,
        variant: "destructive",
      });
      return;
    }
    const json = constructJSON(weeklyPlan, cardioRecords, {
      timeZone,
    });
    console.log(json);
    await handleGeneration(json);
  }

  return (
    <div className="space-y-4">
      <Select
        defaultValue={options[0].value}
        onValueChange={(value) => setWeek(value as Week)}
      >
        <SelectTrigger data-testid='metric-week-selector'>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          {options.map(({ value, label }) => (
            <SelectItem data-testid={value} value={value}>{label}</SelectItem>
          ))}
        </SelectContent>
      </Select>
      <div className="flex justify-end">
        <Button disabled={isLoading} type="button" onClick={generatePreview}>
          {isLoading && (
            <div className="mr-2 flex items-center">
              <Ring />
            </div>
          )}
          Generate Preview
        </Button>
      </div>
    </div>
  );
}
