import { useContext } from "react";
import { Button } from "../ui/button";
import { ActionsContext } from "@/providers/actions";
import Ring from "../ui/ring";
import { RefreshCcwIcon } from "lucide-react";

export default function ChosenAction({ action }: { action: Action }) {
  const { generatePreview, isLoading, preview, executeAction } =
    useContext(ActionsContext);
  const { rating, title, description } = action;
  return (
    <div className="border rounded-md px-4 py-8 flex flex-col gap-4 bg-white text-center items-center w-full">
      <strong className="text-primary font-bold text-4xl flex items-end">
        {rating}
        <sub className="text-sm font-semibold mb-1 ml-1">/ 10</sub>
      </strong>
      <h3 className="text-xl">{title}</h3>
      <p className="opacity-80 text-sm max-w-md">{description}</p>
      {preview ? (
        <div className="flex flex-col gap-4 w-full max-w-md">
          <h4 className="font-medium text-sm self-start">Preview:</h4>
          <div className="flex flex-col items-center gap-4 rounded-md bg-primary/5 p-4">
            {preview.media && (
              <div className="w-full bg-white rounded-md h-48 overflow-hidden">
                <img
                  className="w-full h-full object-contain"
                  src={preview.media}
                  alt=""
                />
              </div>
            )}
            {preview.text && <span className="text-sm">{preview.text}</span>}
          </div>
          <div className="flex items-center justify-end gap-2">
            <Button
              disabled={isLoading.preview || isLoading.execution}
              onClick={generatePreview}
              variant="outline"
            >
              <div className="mr-2 flex items-center">
                {isLoading.preview ? (
                  <Ring color="black" />
                ) : (
                  <RefreshCcwIcon size={14} />
                )}
              </div>
              Regenerate
            </Button>
            <Button
              disabled={isLoading.preview || isLoading.execution}
              onClick={executeAction}
            >
              {isLoading.execution && (
                <div className="mr-2 flex items-center">
                  <Ring />
                </div>
              )}
              Execute
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-4 mt-2">
          <Button disabled={isLoading.preview} onClick={generatePreview}>
            {isLoading.preview && (
              <div className="mr-2 flex items-center">
                <Ring />
              </div>
            )}
            Generate & Preview
          </Button>
        </div>
      )}
    </div>
  );
}
