import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { parseIntRange } from "@/lib/utils";

type Props = {
  values: HeartZoneRange;
  onChange: (key: string, value: any) => void;
};

export default function HeartZoneRangeInput({ values, onChange }: Props) {
  const { from, to } = parseIntRange(values["hr_range"]);
  return (
    <>
      <Label className="text-right" key="label-bpm">
        Session
      </Label>
      <Select
        value={values["zone"]}
        onValueChange={(value) => onChange("zone", value)}
      >
        <SelectTrigger>
          <SelectValue placeholder="Choose session" />
        </SelectTrigger>
        <SelectContent>
          {Array.from(Array(5)).map((_, k) => (
            <SelectItem value={`zone${k + 1}`} key={`zone${k + 1}`}>{`zone${
              k + 1
            }`}</SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Label className="text-right" key="label-bpm-from">
        BPM
      </Label>

      <div className="grid grid-cols-[1fr_max-content_1fr] gap-4">
        <Input
          value={from || ""}
          onChange={(e) =>
            onChange(
              "hr_range",
              `${
                e.target.value === ""
                  ? "("
                  : `[${e.target.value.replace(/\D/g, "")}`
              },${isNaN(to) ? ")" : `${to}]`}`
            )
          }
          maxLength={3}
          key="input-bpm-from"
        />
        <span>-</span>
        <Input
          value={to || ""}
          onChange={(e) =>
            onChange(
              "hr_range",
              `${isNaN(from) ? "(" : `[${from}`},${
                e.target.value === ""
                  ? ")"
                  : `${e.target.value.replace(/\D/g, "")}]`
              }`
            )
          }
          maxLength={3}
          key="input-bpm"
        />
      </div>
      <div></div>
      <p className="text-slate-500 text-sm -mt-2">
        leave blank to cover all lower or greater values
      </p>
    </>
  );
}
