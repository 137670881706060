import {
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getSeconds,
  getYear,
  parse,
  parseISO,
} from "date-fns";
import { CalendarDateTime } from "@internationalized/date";
import {
  format,
  formatInTimeZone,
  fromZonedTime,
  toZonedTime,
} from "date-fns-tz";
import { enGB } from "date-fns/locale/en-GB";

const defaultTimezone = "Africa/Accra"; // UTC + 0

const isValidTimeZone = (timeZone: string) => {
  try {
    format(new Date(), "yyyy-MM-dd HH:mm:ssXXX", { timeZone });
    return true;
  } catch (error) {
    return false;
  }
};

export const convertToPropertiesTimezone = (
  dateStr: string,
  timeZone = "Africa/Accra",
) => {
  const formatStr = "yyyy-MM-dd HH:mm";
  const date = parse(dateStr, formatStr, new Date());
  const utcDate = fromZonedTime(date, timeZone);
  const zonedDate = toZonedTime(utcDate, timeZone);
  const formattedDate = format(zonedDate, "yyyy-MM-dd'T'HH:mm:ssXXX", {
    timeZone,
  });
  return formattedDate;
};

export function convertToPDT(dateString: string, timezone = "Africa/Accra") {
  if (!dateString) return "-";
  const date = new Date(dateString);

  const isTzValid = isValidTimeZone(timezone);

  const time = formatInTimeZone(
    date,
    isTzValid ? timezone : defaultTimezone,
    "MMM dd, yyyy, h:mm aa zzz",
    { locale: enGB },
  );

  return time;
}

export function convertTime(timeString: string) {
  if (!timeString) return "-";
  const time = parseISO(`1970-01-01T${timeString}Z`);

  const minutes = time.getUTCMinutes();
  const hours = time.getUTCHours();
  const seconds = time.getUTCSeconds();

  let result = "";
  if (hours > 0) {
    result += `${hours} ${hours > 1 ? "hrs" : "hr"} `;
  }
  if (minutes > 0 || hours > 0) {
    result += `${minutes} ${minutes > 1 ? "mins" : "min"} `;
  }
  if (seconds > 0) {
    result += `${seconds} ${seconds > 1 ? "secs" : "sec"} `;
  }

  return result.trim();
}

export function convertMinutes(minutes: number) {
  if (minutes < 60) return `${minutes} mins`;
  const rest = minutes % 60;
  console.log(rest);
  const hours = Math.floor(minutes / 60);
  return `${hours} hr${hours > 1 ? "s" : ""}${
    rest > 0 ? ` ${rest} min${rest > 1 ? "s" : ""}` : ""
  }`;
}

export const getCalendarDateTime = (dateTime: string) => {
  const year = getYear(dateTime);
  const month = getMonth(dateTime);
  const day = getDate(dateTime);
  const hour = getHours(dateTime);
  const minute = getMinutes(dateTime);
  const second = getSeconds(dateTime);

  return new CalendarDateTime(year, month + 1, day, hour, minute, second);
};

export const getDateTimeStamp = (date: CalendarDateTime) => {
  return `${date.year}-${date.month}-${date.day} ${date.hour}:${date.minute}`;
};
