import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from "react";

type Tab = "user-context" | "ai-actions" | "stats";

type MessageContext = {
  tab: Tab;
  user: User | null;
  chat: Chat | null;
  message: Message;
  setMessage: Dispatch<SetStateAction<Message>>;
  changeChat: (chat: Chat | null) => void;
  changeUser: (user: User) => void;
  changeTab: (tab: Tab) => void;
};

export const MessageContext = createContext<MessageContext>(null!);

export const defaultMessageState: Message = {
  text: "",
  sender_type: "bot",
};

export default function MessageProvider({ children }: { children: ReactNode }) {
  const [tab, setTab] = useState<Tab>("user-context");
  const [message, setMessage] = useState<Message>(defaultMessageState);
  const [user, setUser] = useState<User | null>(null);
  const [chat, setChat] = useState<Chat | null>(null);

  const changeChat = (newChat: Chat | null) => {
    newChat && localStorage.setItem("last-chat", newChat.id.toString());
    setChat(newChat);
  };

  const changeUser = (newUser: User) => {
    localStorage.setItem("last-user", newUser.id);
    setUser(newUser);
  };

  const changeTab = (newTab: Tab) => setTab(newTab);

  return (
    <MessageContext.Provider
      value={{
        tab,
        chat,
        user,
        message,
        setMessage,
        changeChat,
        changeUser,
        changeTab,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
}
