import ChatTopbar from "./chat-topbar";
import { ChatList } from "./chat-list";
import { Card } from "../card";

export function Chat() {
  return (
    <div className="relative">
      <Card className="md:absolute inset-0 w-full h-full flex flex-col justify-between  max-h-full">
        <ChatTopbar />
        <ChatList />
      </Card>
    </div>
  );
}
