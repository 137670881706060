import ChatSelect from "@/components/messages/chat-select";
import { CardHeader } from "../card";
import { Label } from "../label";

export default function ChatTopbar() {
  return (
    <CardHeader className="w-full h-20 flex p-4 justify-between items-center border-b">
      <div className="w-full h-full flex items-center gap-2">
        <Label>Chat:</Label>
        <ChatSelect />
      </div>
    </CardHeader>
  );
}
