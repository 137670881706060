import { Row } from "@tanstack/react-table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Edit, MoreVertical, Trash2 } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { deleteStat, editStat } from "@/lib/stats";
import { Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { toast } from "@/components/ui/use-toast";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { TIME_ZONES } from "@/const/stats";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import HeartZoneRangeInput from "./inputs/heart-zone-range";

type Props = {
  row: Row<any>;
  tableName: StatsTable;
};

const EXCLUDED_COLUMNS = ["unit"];

export default function Actions({ row, tableName }: Props) {
  const [modal, setModal] = useState({
    editing: false,
    deleting: false,
  });
  const { id, created_at, user_id, ...rest } = row.original;
  const [updated, setUpdated] = useState(rest);
  const queryClient = useQueryClient();
  const { isPending: isDeletePending, mutate: mutateDelete } = useMutation({
    mutationKey: ["stats", tableName, user_id],
    mutationFn: () => deleteStat(id, tableName),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["stats", tableName, user_id],
      });
    },
  });
  const { isPending: isEditPending, mutate: mutateEdit } = useMutation({
    mutationKey: ["stats", tableName, user_id],
    mutationFn: () => editStat(id, tableName, updated),
    onError: (err) =>
      toast({
        title: "Something went wrong!",
        description: err.message,
        variant: "destructive",
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["stats", tableName, user_id],
      });
      toast({
        title: "Successfully updated!",
      });
      setModal((prev) => ({ ...prev, editing: false }));
    },
  });

  return (
    <Dialog
      open={modal.editing}
      onOpenChange={(is) => setModal((prev) => ({ ...prev, editing: is }))}
    >
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">Open menu</span>
            <MoreVertical className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DialogTrigger className="w-full">
            <DropdownMenuItem className="gap-2">
              <Edit size={12} /> Edit
            </DropdownMenuItem>
          </DialogTrigger>
          <DropdownMenuItem
            disabled={isDeletePending}
            onClick={() => mutateDelete()}
            className="gap-2 !text-danger hover:!bg-danger/5"
          >
            <Trash2 size={12} /> Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit {tableName}</DialogTitle>
          <DialogDescription>
            Make changes to the record from {tableName} with the id of {id}
          </DialogDescription>
        </DialogHeader>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            mutateEdit();
          }}
        >
          <div className="grid grid-cols-[max-content_1fr] gap-4 items-center my-4">
            {tableName === "properties" && row.original.name === "timezone" ? (
              <Fragment>
                <Label className="text-right">Value</Label>
                <Select
                  value={String(updated["value"])}
                  onValueChange={(value) =>
                    setUpdated((prev: any) => ({
                      ...prev,
                      value,
                      name: "timezone",
                    }))
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent>
                    {TIME_ZONES.map((zone) => (
                      <SelectItem value={zone} key={zone}>
                        {zone}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </Fragment>
            ) : tableName === "properties" &&
              row.original.name === "default_zone2_target" ? (
              <Fragment>
                <Label className="text-right">Value</Label>
                <Input
                  value={updated["value"]}
                  placeholder="e.g. 40 meaning 40 minutes"
                  onChange={(e) =>
                    setUpdated((prev: any) => ({
                      ...prev,
                      value: e.target.value.replace(/\D/g, ""),
                    }))
                  }
                />
              </Fragment>
            ) : tableName === "heart_zone_ranges" ? (
              <HeartZoneRangeInput
                values={updated}
                onChange={(key, value) =>
                  setUpdated((prev: HeartZoneRange) => ({
                    ...prev,
                    [key]: value,
                  }))
                }
              />
            ) : (
              Object.keys(rest)
                .filter((key) => !EXCLUDED_COLUMNS.includes(key))
                .map((key) => (
                  <Fragment>
                    <Label className="text-right">{key}</Label>
                    <Input
                      value={updated[key]}
                      onChange={(e) =>
                        setUpdated((prev: any) => ({
                          ...prev,
                          [key]: e.target.value,
                        }))
                      }
                    />
                  </Fragment>
                ))
            )}
          </div>
          <DialogFooter>
            <Button disabled={isEditPending} type="submit">
              Save
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
