import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { TIME_ZONES } from "@/const/stats";
import { type SelectProps } from "@radix-ui/react-select";
import { Fragment } from "react";

export default function TimezoneInput({
  value,
  onValueChange,
}: Pick<SelectProps, "value" | "onValueChange">) {
  return (
    <Fragment>
      <Label className="text-right">Value</Label>
      <Select value={value} onValueChange={onValueChange}>
        <SelectTrigger>
          <SelectValue placeholder="Select" />
        </SelectTrigger>
        <SelectContent>
          {TIME_ZONES.map((zone) => (
            <SelectItem value={zone} key={zone}>
              {zone}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </Fragment>
  );
}
