import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { ColumnDef } from "@tanstack/react-table";
import StatsTable from "./table";
import Add from "./add";
import { convertMinutes } from "@/lib/format";

const columns: (ColumnDef<Omit<UserProperty, "unit">> & ColumnProps)[] = [
  {
    accessorKey: "name",
    header: "Name",
    defaultValue: "",
    required: true,
  },
  {
    accessorKey: "value",
    header: "Value",
    defaultValue: "",
    required: true,
    cell: ({ row }) =>
      row.original.name === "default_zone2_target"
        ? convertMinutes(parseInt(row.original.value))
        : row.original.value,
  },
];

export default function Properties() {
  return (
    <Card>
      <CardHeader className="flex-row items-center justify-between">
        <span>Properties</span>
        <Add<Omit<UserProperty, "unit">>
          tableName="properties"
          columns={columns}
          buttonTestId="add-properties-button"
        />
      </CardHeader>
      <CardContent>
        <StatsTable
          columns={columns}
          tableName="properties"
          tableTitle="Properties"
        />
      </CardContent>
    </Card>
  );
}
