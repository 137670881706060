import ChosenAction from "@/components/ai-actions/chosen";
import ManualAction from "@/components/ai-actions/manual";
import ManualPrompt from "@/components/ai-actions/manual-prompt";
import ActionRef from "@/components/ai-actions/ref";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import Ring from "@/components/ui/ring";
import { ActionsContext } from "@/providers/actions";
import { AnimatePresence } from "framer-motion";
import { ChevronLeftIcon } from "lucide-react";
import { FormEvent, useContext } from "react";

export default function AIActions() {
  const { isLoading, actions, chosenAction, listActions, reset } =
    useContext(ActionsContext);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    listActions();
  };

  return (
    <div className="flex-1 w-full">
      {actions.length > 0 ? (
        <div>
          <Button
            onClick={reset}
            type="button"
            variant="ghost"
            size="sm"
            className="h-8 mb-4 gap-1"
          >
            <ChevronLeftIcon size={16} className="mt-0.5" /> Start Over
          </Button>
          <div className="flex flex-col md:grid grid-cols-2 gap-4">
            <div
              className={`flex flex-col md:grid ${
                typeof chosenAction === "number"
                  ? "grid-cols-1"
                  : "grid-cols-2 col-span-2"
              } gap-4 w-full self-start`}
            >
              <AnimatePresence>
                {actions
                  .sort(({ rating: a }, { rating: b }) => b - a)
                  .map((action, k) => (
                    <ActionRef {...action} index={k} key={`action:${k}`} />
                  ))}
                <ManualPrompt actionsLength={actions.length} />
              </AnimatePresence>
            </div>
            <div className="order-first md:order-[0]">
              {typeof chosenAction === "number" &&
                (chosenAction === -1 ? (
                  <ManualAction />
                ) : (
                  <ChosenAction action={actions[chosenAction]} />
                ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-rows-[repeat(3,max-content)] max-w-max gap-8">
          <Card>
            <CardHeader>
              <CardTitle className="text-lg">
                Generate AI Actions based on a specific message
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription className="max-w-lg">
                Hover over some message on the left and click the "Magic Wand"
                button to get customized suggestions based on the selected
                message.
              </CardDescription>
            </CardContent>
          </Card>
          <p className="text-center">OR</p>
          <Card>
            <CardHeader>
              <CardTitle className="text-lg">
                Generate AI Actions based on the last 10 messages
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription className="max-w-lg">
                Based on the last 10 messages, the AI provides tailored
                recommendations. Currently work in progress on tuning its
                relevance and accuracy.
              </CardDescription>
            </CardContent>
            <CardFooter>
              <form onSubmit={handleSubmit}>
                <Button disabled={isLoading.list}>
                  {isLoading.list && (
                    <div className="mr-2 flex items-center">
                      <Ring />
                    </div>
                  )}
                  Generate AI Actions from recent replies
                </Button>
              </form>
            </CardFooter>
          </Card>
        </div>
      )}
    </div>
  );
}
