import { getUsers } from "@/lib/messages";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../ui/select";
import { useContext, useEffect } from "react";
import { MessageContext } from "@/providers/message";
import { useQuery } from "@tanstack/react-query";
import { toast } from "../ui/use-toast";
import { constructName } from "@/lib/utils";

const searchQuery = window.location.search;
const searchParams = new URLSearchParams(searchQuery);

export default function UserSelect() {
  const { data: users, isLoading } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
  });
  const { user, changeUser, changeTab } = useContext(MessageContext);

  useEffect(() => {
    if (!users || users.length === 0) return;
    const initialTelegramId = searchParams.get("telegram_id");
    if (initialTelegramId) {
      const userFromParams = users.find(
        ({ telegram_id }) => telegram_id?.toString() === initialTelegramId
      );
      if (userFromParams) {
        changeUser(userFromParams);
      } else {
        toast({
          variant: "destructive",
          title: "User not found!",
          description: "User with the provided telegram_id has not been found",
        });
        searchParams.delete("chat_id");
        if (users.length === 1 && user?.id !== users[0].id) {
          changeUser(users[0]);
        }
      }
      searchParams.delete("telegram_id");
      const query = searchParams.toString();
      window.history.replaceState(
        null,
        "",
        window.location.pathname + (query.length > 0 ? "?" + query : "")
      );
      return;
    }
    const idFromStorage = localStorage.getItem("last-user");
    if (idFromStorage) {
      const userFromStorage = users.find(({ id }) => id === idFromStorage);
      if (userFromStorage) {
        changeUser(userFromStorage);
        return;
      }
    }
    if (users.length > 1 || user?.id === users[0].id) return;
    changeUser(users[0]);
  }, [users]);

  return (
    <div className="flex-1 max-w-xs">
      <Select
        disabled={isLoading || !users || users.length < 2}
        required
        value={users?.find((item) => item?.id === user?.id)?.id.toString()}
        onValueChange={(value) => {
          const chosen = users?.find((user) => user.id.toString() === value);
          chosen && changeUser(chosen);
          changeTab("user-context");
        }}
      >
        <SelectTrigger>
          {user ? (
            user.supabase_id ? (
              user.email
            ) : user.telegram_id ? (
              `${user.first_name}${user.last_name ? " " + user.last_name : ""}`
            ) : (
              "Unknown"
            )
          ) : (
            <span className="pointer-events-none">Select a user</span>
          )}
        </SelectTrigger>
        {!isLoading && users && (
          <SelectContent>
            {users
              .sort((a, b) => {
                const aName = a ? constructName(a).toLowerCase() : "Unknown";
                const bName = b ? constructName(b).toLowerCase() : "Unknown";
                if (aName > bName) return 1;
                if (aName < bName) return -1;
                return 0;
              })
              .map((user) => (
                <UserRef {...user} key={user.id} />
              ))}
          </SelectContent>
        )}
      </Select>
    </div>
  );
}

const UserRef = (props: User) => (
  <SelectItem value={props.id.toString()}>
    {constructName(props)}
  </SelectItem>
);
