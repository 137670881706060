import { useContext } from "react";
import { Button } from "../ui/button";
import { ActionsContext } from "@/providers/actions";
import Ring from "../ui/ring";
import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";
import { Label } from "../ui/label";
import { RefreshCcwIcon } from "lucide-react";

export default function ManualAction() {
  const {
    generatePreview,
    isLoading,
    preview,
    manualPrompt,
    setManualPrompt,
    executeAction,
  } = useContext(ActionsContext);

  const { title, description } = manualPrompt;

  return (
    <div className="border rounded-md px-8 py-8 flex flex-col gap-4 bg-white w-full">
      <h3 className="text-xl self-center text-center">Manual prompt</h3>
      <div className="space-y-1.5">
        <Label htmlFor="manual-prompt-title">Title</Label>
        <Input
          id="manual-prompt-title"
          className="opacity-80 text-sm w-full"
          value={title}
          onChange={(e) =>
            setManualPrompt((prev) => ({ ...prev, title: e.target.value }))
          }
        />
      </div>
      <div className="space-y-1.5">
        <Label htmlFor="manual-prompt-description">Description</Label>
        <Textarea
          id="manual-prompt-description"
          className="opacity-80 text-sm w-full"
          value={description}
          onChange={(e) =>
            setManualPrompt((prev) => ({
              ...prev,
              description: e.target.value,
            }))
          }
        ></Textarea>
      </div>
      {preview ? (
        <div className="flex flex-col gap-4 w-full flex-1">
          <h4 className="font-medium text-sm self-start">Preview:</h4>
          <div className="flex flex-col items-center gap-4 rounded-md bg-primary/5 p-4">
            {preview.media && (
              <div className="w-full bg-white rounded-md h-48 overflow-hidden">
                <img
                  className="w-full h-full object-contain"
                  src={preview.media}
                  alt=""
                />
              </div>
            )}
            {preview.text && <span className="text-sm">{preview.text}</span>}
          </div>
          <div className="flex items-center justify-end gap-2">
            <Button
              disabled={isLoading.preview || isLoading.execution}
              onClick={generatePreview}
              variant="outline"
            >
              <div className="mr-2 flex items-center">
                {isLoading.preview ? (
                  <Ring color="black" />
                ) : (
                  <RefreshCcwIcon size={14} />
                )}
              </div>
              Regenerate
            </Button>
            <Button
              className="self-end"
              disabled={isLoading.preview || isLoading.execution}
              onClick={executeAction}
            >
              {isLoading.execution && (
                <div className="mr-2 flex items-center">
                  <Ring />
                </div>
              )}
              Execute
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-4 mt-2 self-center">
          <Button disabled={isLoading.preview} onClick={generatePreview}>
            {isLoading.preview && (
              <div className="mr-2 flex items-center">
                <Ring />
              </div>
            )}
            Generate & Preview
          </Button>
        </div>
      )}
    </div>
  );
}
