import { supabase } from "../supabase";
import { formatError } from "../utils";

export async function sendMessage(message: SupabaseMessage) {
  const formData = new FormData();

  Object.keys(message).forEach((key) => {
    const value = message[key as keyof Omit<SupabaseMessage, "sender_id">];
    value && formData.append(key, value);
  });

  const { data, error } = await supabase.functions.invoke("send-message", {
    body: formData,
  });

  const err = await formatError(error);

  if (err) {
    return {
      error: err,
    };
  }

  return { data };
}

export async function generateWeeklyGraph(body: string) {
  const { data, error } = await supabase.functions.invoke("weekly-graph", {
    body,
  });

  const err = await formatError(error);

  if (err) {
    return {
      error: err,
    };
  }

  return { data };
}

export async function getChats(user_id: string) {
  const { data, error } = await supabase.from("chats_users").select(
    "...chats(*)",
  ).eq("user_id", user_id).returns<Chat[]>();

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export async function getUsers() {
  const { data, error } = await supabase
    .from("users")
    .select("*")
    .returns<User[]>();

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export async function getMessages(chat_id: number) {
  const { data, error } = await supabase
    .from("messages")
    .select("*, sender:users(first_name, last_name, username)")
    .eq("chat_id", chat_id)
    .order("created_at", { ascending: true })
    .returns<MessageWithSender[]>();
  console.log({ error });
  if (error) {
    throw new Error(error.message);
  }
  return data;
}
