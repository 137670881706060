import { useContext } from "react";
import { CardContent, CardHeader } from "@/components/ui/card";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { MessageContext } from "@/providers/message";

export default function Message() {
  const {
    chat,
    message: { text },
    setMessage,
  } = useContext(MessageContext);

  return (
    <div>
      <CardHeader className="text-xl font-medium">Send a message</CardHeader>
      <CardContent className="space-y-3">
        <div className="space-y-1.5">
          <Label
            htmlFor="message"
            className={!chat?.id ? "opacity-80" : "opacity-100"}
          >
            Your Message
          </Label>
          <Textarea
            disabled={!chat?.id}
            placeholder="Hello world!"
            className="min-h-[10rem]"
            value={text}
            onChange={(e) =>
              setMessage((prev) => ({ ...prev, text: e.target.value }))
            }
            id="message"
            name="message"
          />
          <p
            className={`text-sm text-muted-foreground ${
              !chat?.id ? "opacity-80" : "opacity-100"
            }`}
          >
            Your message will be sent through the Telegram bot.
          </p>
        </div>
      </CardContent>
    </div>
  );
}
