import { defaultStats } from "@/const/stats";
import { getStats } from "@/lib/stats";
import { useQuery } from "@tanstack/react-query";

export default function useStats(user: User | null) {
  const stats: Stats = Object.keys(defaultStats).reduce(
    (prev, curr) => ({
      ...prev,
      [curr]:
        useQuery({
          queryKey: ["stats", curr, user?.id],
          queryFn: () => getStats(curr as StatsTable, user?.id),
          enabled: !!user,
        }).data || [],
    }),
    { cardio_records: [], weekly_plans: [], properties: [] }
  );
  return stats;
}
