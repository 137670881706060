import moment from "moment";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../tooltip";
import { supabase } from "@/lib/supabase";
import { cn, getSenderName } from "@/lib/utils";
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTrigger } from "../dialog";
import { Avatar, AvatarFallback } from "../avatar";
import useTimezone from "@/hooks/useTimezone";
import { WandIcon } from "lucide-react";
import { ActionsContext } from "@/providers/actions";
import { MessageContext } from "@/providers/message";

type Props = { index: number; onLoad: () => void };

export default function ChatBubble({
  index,
  onLoad,
  ...message
}: MessageWithSender & Props) {
  const { isLoading, listActions, reset } = useContext(ActionsContext);
  const { changeTab } = useContext(MessageContext);
  const {
    sender_type,
    sender,
    media_url,
    text,
    created_at,
    media_source_type,
  } = message;
  const [mediaUrl, setMediaUrl] = useState(media_url);
  const timeZone = useTimezone();

  useEffect(() => {
    if (media_source_type === "url" || !media_url) return;
    (async () => {
      const { data } = await supabase.storage
        .from("chat_attachments")
        .createSignedUrl(media_url, 60);
      setMediaUrl(data?.signedUrl);
    })();
  }, [media_url, media_source_type]);

  const createdAt: Date | null = created_at ? new Date(created_at) : null;
  const fullTime = createdAt
    ? new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric",
        timeZone,
        timeZoneName: "short",
        day: "2-digit",
        month: "short",
      }).format(createdAt)
    : "No date";

  const isFromUser = sender_type === "user";
  return (
    <motion.div
      layout
      initial={{ opacity: 0, scale: 1, y: 50, x: 0 }}
      animate={{ opacity: 1, scale: 1, y: 0, x: 0 }}
      exit={{ opacity: 0, scale: 1, y: 1, x: 0 }}
      transition={{
        opacity: { duration: 0.1 },
        layout: {
          type: "spring",
          bounce: 0.3,
          duration: index * 0.05 + 0.2,
        },
      }}
      style={{
        originX: 0.5,
        originY: 0.5,
      }}
      className={cn(
        "flex flex-col py-2 first:pt-6 last:pb-6 whitespace-pre-wrap",
        isFromUser ? "items-start pl-4" : "items-end pr-4"
      )}
    >
      <div
        data-id="chat-bubble"
        className={`grid grid-rows-[18px_max-content] gap-y-2 gap-x-3 group ${
          isFromUser
            ? "grid-cols-[40px_max-content_36px]"
            : "grid-cols-[max-content_40px]"
        }`}
      >
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger
              className={`text-slate-400 max-w-max text-[12px] row-[1/2] ${
                isFromUser ? "col-[2/3]" : "col-[1/2] ml-auto"
              }`}
            >
              {moment(created_at).fromNow()}
            </TooltipTrigger>
            <TooltipContent className="text-[12px]">{fullTime}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger
              asChild
              className={`row-[2/3] h-max ${
                isFromUser ? "col-[1/2]" : "col-[2/3]"
              }`}
            >
              <Avatar className="flex justify-center items-center h-10 w-10">
                <AvatarFallback
                  className={isFromUser ? "bg-slate-100" : "bg-blue-50"}
                >
                  {getSenderName(sender, {
                    initials: true,
                    isBot: !isFromUser,
                  })}
                </AvatarFallback>
              </Avatar>
            </TooltipTrigger>
            <TooltipContent className="text-[12px]">
              {getSenderName(sender, { isBot: !isFromUser })}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <div
          className={`flex flex-col rounded-md max-w-xs row-[2/3] ${
            isFromUser
              ? "col-[2/3] justify-self-start bg-slate-100"
              : "col-[1/2] justify-self-end bg-blue-50"
          }`}
        >
          {media_url && media_source_type && (
            <Dialog>
              <DialogTrigger>
                <div className="w-full h-full grid place-content-center p-1">
                  <img
                    onLoad={onLoad}
                    className="max-h-[16rem] object-contain w-full h-full"
                    src={mediaUrl}
                    alt=""
                  />
                </div>
              </DialogTrigger>
              <DialogContent className="max-h-screen w-screen max-w-max">
                <div className="w-full relative flex items-center justify-center">
                  <img
                    className="rounded-md max-h-[calc(100vh-96px)]"
                    src={mediaUrl}
                    alt=""
                  />
                </div>
              </DialogContent>
            </Dialog>
          )}
          {text && (
            <span className="bg-accent p-3 rounded-md text-sm break-words">
              {text}
            </span>
          )}
        </div>
        {isFromUser && (
          <div className="col-[3/4] row-[2/3] flex items-center">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger
                  disabled={isLoading.list}
                  onClick={() => {
                    reset();
                    changeTab("ai-actions");
                    listActions(message);
                  }}
                  className="h-9 w-9 rounded-full bg-slate-100 grid place-content-center opacity-0 group-hover:opacity-100 transition-opacity hover:disabled:opacity-60"
                >
                  <WandIcon size={16} />
                </TooltipTrigger>
                <TooltipContent className="text-[12px]">
                  Generate AI actions on this reply
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      </div>
    </motion.div>
  );
}
