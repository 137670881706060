import MessageProvider from "@/providers/message";
import Header from "./header";
import { Chat } from "./chat/chat";

import Pages from "./pages";
import ActionsProvider from "@/providers/actions";
import UserSelect from "../messages/user-select";

export default function Layout() {
  return (
    <div className="min-h-screen bg-light p-4 flex flex-col">
      <MessageProvider>
        <div className="flex items-center gap-4 justify-between">
          <Header
            left={
              <div className="flex items-center gap-4 flex-1">
                <h1 className="font-medium">M3M Coach Dashboard</h1>
                <UserSelect />
              </div>
            }
          />
        </div>
        <div className="flex flex-col md:grid grid-cols-[1fr_2fr] gap-4 md:h-[calc(100vh-88px)] mt-4">
          <ActionsProvider>
            <Chat />
            <Pages />
          </ActionsProvider>
        </div>
      </MessageProvider>
    </div>
  );
}
