import { ActionsContext } from "@/providers/actions";
import { useContext } from "react";
import { motion } from "framer-motion";

export default function ManualPrompt({
  actionsLength,
}: {
  actionsLength: number;
}) {
  const { chosenAction, chooseAction } = useContext(ActionsContext);
  const index = actionsLength;
  const isChosen = chosenAction === -1;
  return (
    <motion.button
      layout
      initial={{ opacity: 0, scale: 1, y: 25, x: 0 }}
      animate={{ opacity: 1, scale: 1, y: 0, x: 0 }}
      exit={{ opacity: 0, scale: 1, y: 1, x: 0 }}
      transition={{
        opacity: { duration: 1 },
        layout: {
          type: "spring",
          bounce: 0.3,
          duration: 0.2,
        },
        delay: index * 0.1,
      }}
      style={{
        originX: 0.5,
        originY: 0.5,
      }}
      className={`rounded-md border gap-x-4 p-4 grid grid-cols-[max-content_1fr] text-left ${
        isChosen ? "bg-primary/5 border-primary/20" : "bg-white"
      }`}
      onClick={() => chooseAction(-1)}
    >
      <strong className="text-3xl font-bold text-primary row-[1/3] col-[1/2] self-center">
        -
      </strong>
      <h3 className="text-lg self-end">Manual prompt</h3>
      <p className="text-sm opacity-80 line-clamp-1">
        Create your own prompt for AI
      </p>
    </motion.button>
  );
}
