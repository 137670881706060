import { getStats } from "@/lib/stats";
import { MessageContext } from "@/providers/message";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

export default function useTimezone(): string | undefined {
  const { user } = useContext(MessageContext);
  const { data } = useQuery({
    queryKey: ["stats", "properties", user?.id],
    queryFn: () => getStats("properties", user?.id),
    enabled: !!user,
  });
  const timezone: string | undefined = (data as UserProperty[])?.find(
    ({ name }) => name === "timezone"
  )?.value;
  return timezone;
}
