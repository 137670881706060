import { useEffect, useState } from "react";
import { supabase } from "./lib/supabase";
import { Session } from "@supabase/supabase-js";
import SignIn from "./pages/auth/sign-in";
import Layout from "./components/ui/layout";
import { toast } from "./components/ui/use-toast";

function App() {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    supabase.auth
      .getSession()
      .then(({ data: { session } }) => setSession(session));

    supabase.auth.onAuthStateChange((_event, session) => setSession(session));
  }, []);

  useEffect(() => {
    if (!session) return;
    (async () => {
      const { data, error } = await supabase
        .from("users")
        .select("role")
        .eq("supabase_id", session.user.id)
        .single();
      console.log(data, error, session.user.id);
      if (!data) {
        await supabase.auth.signOut();
        toast({
          title: "No account found!",
          description: "Your account does not have a profile",
          variant: "destructive",
        });
        return;
      }
      if (data.role !== "coach") {
        await supabase.auth.signOut();
        toast({
          title: "Unauthorized!",
          description: "You do not have permission to view this page",
        });
        return;
      }
    })();
  }, [session]);

  return session ? <Layout /> : <SignIn />;
}

export default App;
