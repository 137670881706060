import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { ColumnDef } from "@tanstack/react-table";
import StatsTable from "./table";
import Add from "./add";
import { parseIntRange } from "@/lib/utils";

const columns: (ColumnDef<HeartZoneRange> & ColumnProps)[] = [
  {
    accessorKey: "zone",
    header: "Session",
    defaultValue: "",
  },
  {
    accessorKey: "hr_range",
    header: "Heart Rate Range",
    defaultValue: "(,)",
    cell: ({ row }) => {
      const parsed = parseIntRange(row.original.hr_range);
      return isNaN(parsed.from)
        ? `<${parsed.to + 1}`
        : isNaN(parsed.to)
        ? `${parsed.from}+`
        : `${parsed.from} - ${parsed.to}`;
    },
  },
];

export default function HeartZoneRanges() {
  return (
    <Card>
      <CardHeader className="flex-row items-center justify-between">
        <span>Heart Zone Ranges</span>
        <Add<HeartZoneRange>
          tableName="heart_zone_ranges"
          columns={columns}
          buttonTestId="add-heart-zone-ranges-button"
        />
      </CardHeader>
      <CardContent>
        <StatsTable
          columns={columns}
          tableName="heart_zone_ranges"
          tableTitle="Heart Zone Ranges"
        />
      </CardContent>
    </Card>
  );
}
