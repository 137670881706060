import { FormEvent, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { Label } from "@/components/ui/label";
import { LoaderIcon } from "lucide-react";
import { supabase } from "@/lib/supabase";
import { Input } from "@/components/ui/input";

export default function SignIn() {
  const [isLoading, setIsLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    setIsLoading(true);
    const { email, password } = credentials;
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    error &&
      toast({
        title: "Something went wrong!",
        description: error.message,
        variant: "destructive",
      });
    setIsLoading(false);
  }

  return (
    <div className="min-h-screen flex justify-center bg-light">
      <form onSubmit={handleSubmit} className="w-full max-w-sm m-auto bg-white">
        <Card>
          <CardHeader className="text-xl font-medium space-y-3">
            <h1 className="font-medium">Sign in</h1>
            <p className="text-sm opacity-80">
              Enter your email below to login to your account.
            </p>
          </CardHeader>
          <CardContent className="space-y-3">
            <div className="space-y-1.5">
              <Label htmlFor="email">Email</Label>
              <Input
                placeholder="example@mail.com"
                type="email"
                required
                value={credentials.email}
                onChange={(e) =>
                  setCredentials((prev) => ({ ...prev, email: e.target.value }))
                }
                id="email"
                name="email"
              />
            </div>
            <div className="space-y-1.5">
              <Label htmlFor="password">Password</Label>
              <Input
                placeholder="*********"
                required
                type="password"
                value={credentials.password}
                onChange={(e) =>
                  setCredentials((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
                id="password"
                name="password"
              />
            </div>
          </CardContent>
          <CardFooter>
            <Button
              type="submit"
              disabled={isLoading}
              className="font-medium w-full"
            >
              {isLoading ? (
                <LoaderIcon size={16} className="animate-spin" />
              ) : (
                "Send"
              )}
            </Button>
          </CardFooter>
        </Card>
      </form>
    </div>
  );
}
