import { supabase } from "@/lib/supabase";
import { toast } from "./use-toast";
import { FormEvent, ReactNode } from "react";
import { Button } from "./button";

export default function Header({ left }: { left: ReactNode }) {
  function handleLogOut(e: FormEvent) {
    e.preventDefault();
    supabase.auth.signOut().then(({ error }) => {
      if (error) {
        toast({
          title: "There was an error!",
          description: error.message,
          variant: "destructive",
        });
      }
    });
  }
  return (
    <header className="flex items-center justify-between gap-4 flex-1 h-max">
      {left || <div></div>}
      <form onSubmit={handleLogOut}>
        <Button variant="outline" size="sm" className="h-9 px-4">
          Sign out
        </Button>
      </form>
    </header>
  );
}
