import { getChats } from "@/lib/messages";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { useContext, useEffect } from "react";
import { MessageContext } from "@/providers/message";
import { useQuery } from "@tanstack/react-query";
import { toast } from "../ui/use-toast";

export default function ChatSelect() {
  const { user, chat, changeChat } = useContext(MessageContext);
  const { data: chats, isLoading } = useQuery({
    queryKey: ["chats", user?.id],
    queryFn: () => getChats(user!.id),
    enabled: !!user,
  });

  useEffect(() => {
    if (!chats || chats.length === 0) {
      changeChat(null);
      return;
    }
    const searchQuery = window.location.search;
    const searchParams = new URLSearchParams(searchQuery);
    const initialChatId = searchParams.get("chat_id");
    if (initialChatId) {
      const chatFromParams = chats.find(
        ({ id }) => id.toString() === initialChatId
      );
      window.history.replaceState(null, "", window.location.pathname);
      if (chatFromParams) {
        changeChat(chatFromParams);
        return;
      } else {
        toast({
          variant: "destructive",
          title: "Chat not found!",
          description: "Chat with the provided chat_id has not been found",
        });
      }
    }
    const idFromStorage = localStorage.getItem("last-chat");
    if (idFromStorage) {
      const userFromStorage = chats.find(
        ({ id }) => id.toString() === idFromStorage
      );
      if (userFromStorage) {
        changeChat(userFromStorage);
        return;
      }
    }
    if (chats.length > 1 || chat?.id === chats[0].id) return;
    changeChat(chats[0]);
  }, [chats]);
  // const [chats, setChats] = useState<Chat[]>([]);
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   (async () => {
  //     const { results } = await getChats();
  //     setChats(results);
  //     const initialChatId = searchParams.get("chat_id");
  //     if (initialChatId) {
  //       const found = results.find((item) => item.id == initialChatId);
  //       found && changeChat(found);
  //     } else {
  //       const idFromStorage = localStorage.getItem("last-chat");
  //       if (idFromStorage) {
  //         const found = results.find((item) => item.id == idFromStorage);
  //         found && changeChat(found);
  //       }
  //     }
  //     setIsLoading(false);
  //   })();
  // }, []);

  return (
    <Select
      disabled={!user || !chats || chats.length === 0 || isLoading}
      required
      value={chat?.id.toString()}
      onValueChange={(value) => {
        const chosen = chats?.find((item) => item.id.toString() === value);
        chosen && changeChat(chosen);
      }}
    >
      <SelectTrigger>
        <SelectValue placeholder="Select a chat" />
      </SelectTrigger>
      {!isLoading && chats && (
        <SelectContent>
          {chats
            .sort((a, b) => {
              const aName = a.name.toLowerCase();
              const bName = b.name.toLowerCase();
              if (aName > bName) return 1;
              if (aName < bName) return -1;
              return 0;
            })
            .map((chat) => (
              <ChatRef {...chat} key={chat.id} />
            ))}
        </SelectContent>
      )}
    </Select>
  );
}

const ChatRef = ({ id, name, type }: Chat) => (
  <SelectItem value={id.toString()}>
    <div className="flex items-center gap-1">
      <span>{name}</span>
      <span className="opacity-80 text-sm text-primary">({type})</span>
    </div>
  </SelectItem>
);
