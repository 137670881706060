import { useContext } from "react";
import { Button } from "../../ui/button";
import { MessageContext } from "@/providers/message";
import { LoaderIcon } from "lucide-react";
import { CardFooter } from "../../ui/card";

export default function SubmitButton({ isLoading }: { isLoading: boolean }) {
  const {
    chat,
    message: { text, image, graph },
  } = useContext(MessageContext);

  const isDisabled = !chat || (!text && !image && !graph);

  return (
    <CardFooter className="flex justify-end col-span-2 items-end">
      <Button disabled={isDisabled || isLoading} className="font-medium">
        {isLoading ? <LoaderIcon size={16} className="animate-spin" /> : "Send"}
      </Button>
    </CardFooter>
  );
}
