import {
  FunctionsError,
  FunctionsFetchError,
  FunctionsHttpError,
  FunctionsRelayError,
} from "@supabase/supabase-js";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export async function formatError(
  error: FunctionsError,
): Promise<string | null> {
  if (error instanceof FunctionsHttpError) {
    console.log("http", { error });
    const errorContext = await error.context.json();
    return errorContext.message;
  } else if (error instanceof FunctionsRelayError) {
    console.log("relay", { error });
    return "Relay error" + error.message;
  } else if (error instanceof FunctionsFetchError) {
    console.log("fetch", { error });
    return "Fetch error" + error.message;
  }
  return null;
}

export function getSenderName(
  sender: User | null,
  options: { initials?: boolean; isBot?: boolean },
): string {
  if (!sender) {
    if (options.initials) return options.isBot ? "B" : "-";
    return options.isBot ? "Bot" : "Unknown";
  }
  if (sender.username) {
    return options.initials ? sender.username.charAt(0) : sender.username;
  }
  return options.initials
    ? sender.first_name?.charAt(0) +
      (sender.last_name ? sender.last_name.charAt(0) : "")
    : sender.first_name + " " + (sender.last_name || "");
}

export function parseDate(dateString: string) {
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(year, month - 1, day); // Month is zero-based in Date constructor
}

export function toDateString(date: Date | string) {
  let validDate: Date;
  if (typeof date === "string") {
    validDate = new Date(date);
  } else {
    validDate = date;
  }
  return `${validDate.getFullYear()}-${
    validDate.getMonth() + 1
  }-${validDate.getDate()}`;
}

export function parseIntRange(range: string): { from: number; to: number } {
  let formatted = range.slice(1, -1);
  const [fromStr, toStr] = formatted.split(",");
  const from = parseInt(fromStr) + (range[0] === "(" ? 1 : 0);
  const to = parseInt(toStr) - (range[range.length - 1] === ")" ? 1 : 0);
  return { from, to };
}

export const constructName = (
  { supabase_id, telegram_id, first_name, last_name, email, id }: User,
) => {
  if (supabase_id && telegram_id) {
    return `${first_name}${last_name ? " " + last_name : ""} (${email})`;
  }
  if (supabase_id) return email!;
  if (telegram_id) return `${first_name}${last_name ? " " + last_name : ""}`;
  return id;
};
