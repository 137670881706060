import type {} from "ldrs";
import { useEffect } from "react";

export default function Ring({ color = "white" }: { color?: string }) {
  useEffect(() => {
    async function getLoader() {
      const { ring } = await import("ldrs");
      ring.register();
    }
    getLoader();
  }, []);
  return (
    <l-ring
      size="16"
      stroke="2"
      bg-opacity="0"
      speed="2"
      color={color}
    ></l-ring>
  );
}
