import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { ColumnDef } from "@tanstack/react-table";
import StatsTable from "./table";
import Add from "./add";
import { convertTime } from "@/lib/format";

const columns: (ColumnDef<WeeklyPlan> & ColumnProps)[] = [
  {
    accessorKey: "from",
    header: "From",
    defaultValue: "",
    placeholder: "",
    required: true,
  },
  {
    accessorKey: "to",
    header: "To",
    defaultValue: "",
    placeholder: "",
    required: true,
  },
  {
    accessorKey: "zone2_target",
    header: "Zone 2",
    cell: ({ row }) => convertTime(row.original.zone2_target),
    defaultValue: "",
    placeholder: "e.g. 30m 2s or 30 mins 2 seconds or 00:30:02",
  },
  {
    accessorKey: "hiit_target",
    header: "Hiit",
    cell: ({ row }) => convertTime(row.original.hiit_target),
    defaultValue: "",
    placeholder: "e.g. 30m 2s or 30 mins 2 seconds or 00:30:02",
  },
];

export default function WeeklyPlans() {
  return (
    <Card>
      <CardHeader className="flex-row items-center justify-between">
        <span>Weekly Plans</span>
        <Add<WeeklyPlan>
          tableName="weekly_plans"
          columns={columns}
          buttonTestId="add-weekly-plan-button"
        />
      </CardHeader>
      <CardContent>
        <StatsTable
          columns={columns}
          tableName="weekly_plans"
          tableTitle="Weekly Plans"
        />
      </CardContent>
    </Card>
  );
}
