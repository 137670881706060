import { AnimatePresence } from "framer-motion";
import { CardContent } from "../card";
import { ScrollArea } from "../scroll-area";
import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { MessageContext } from "@/providers/message";
import ChatBubble from "./chat-bubble";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getMessages } from "@/lib/messages";
import { Skeleton } from "../skeleton";
import { cn } from "@/lib/utils";
import { supabase } from "@/lib/supabase";

export function ChatList() {
  const { chat } = useContext(MessageContext);
  const scrollAreaRef = useRef<HTMLDivElement | null>(null);
  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery({
    queryKey: ["chat", "messages", chat?.id],
    queryFn: () => getMessages(chat!.id),
    enabled: !!chat,
  });

  useEffect(() => {
    supabase
      .channel("table_db_changes")
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "messages" },
        async (payload) => {
          const message = payload.new as SupabaseMessage & { id: string };
          let sender: User | null = null;
          if (message.sender_id) {
            const { data } = await supabase
              .from("messages")
              .select("sender:users(first_name, last_name, username)")
              .eq("id", message.id)
              .returns<MessageWithSender[]>()
              .single();
            sender = data ? (data.sender as User) : null;
          }
          queryClient.setQueryData(
            ["chat", "messages", message.chat_id],
            (prev: SupabaseMessage[]) =>
              prev
                ? [...prev, { ...message, sender }]
                : [{ ...message, sender }]
          );
        }
      )
      .subscribe();
  }, []);

  const scrollDown = () => {
    if (!scrollAreaRef.current) return;
    scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
  };

  useLayoutEffect(scrollDown, [data]);

  return (
    <CardContent className="w-full overflow-y-auto overflow-x-hidden h-full flex flex-col !p-0">
      {isLoading ? (
        <div className="w-full h-full flex flex-col justify-end flex-1 max-h-[24rem] md:max-h-none">
          {Array.from(Array(12)).map((_m, k) => (
            <div
              className={cn(
                "flex gap-3",
                k % 2 === 0
                  ? "flex-row self-start ml-4"
                  : "flex-row-reverse self-end mr-4"
              )}
            >
              <Skeleton className="h-10 w-10 rounded-full" />
              <Skeleton
                key={`message-skeleton-${k}`}
                className={cn(
                  "py-2 first:mt-6 last:mb-6 whitespace-pre-wrap h-10 w-48 rounded-full"
                )}
              />
            </div>
          ))}
        </div>
      ) : data && data.length > 0 ? (
        <ScrollArea
          ref={(ref) => (scrollAreaRef.current = ref)}
          className="w-full h-full flex flex-col max-h-[24rem] md:max-h-none"
        >
          <AnimatePresence>
            {data.map((message, index) => (
              <ChatBubble
                {...message}
                onLoad={scrollDown}
                index={index}
                key={`message:${index}`}
              />
            ))}
          </AnimatePresence>
        </ScrollArea>
      ) : (
        <div className="w-full h-full grid place-content-center">
          <p className="text-sm">
            {chat
              ? "No message history yet!"
              : "Select a chat channel to get started"}
          </p>
        </div>
      )}
    </CardContent>
  );
}
