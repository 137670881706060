import { sendMessage } from "../messages";
import { supabase } from "../supabase";
import { formatError } from "../utils";
import { toast } from "@/components/ui/use-toast";

enum ExecutableHint {
  SEND_TEXT_MESSAGE = "send-text-message",
  SEND_GRAPH = "send-graph",
  STORE_USER_PROPERTIES = "store-user-properties",
  STORE_CARDIO_RECORDS = "store-cardio-records",
  STORE_WEEKLY_PLANS = "store-weekly-plans",
}

export async function getActions(
  stats: Stats,
  messages: SupabaseMessage[],
  user: User | null,
  chosenMessage?: SupabaseMessage
) {
  const { data, error } = await supabase.functions.invoke("list-actions", {
    body: { stats, user, messages, chosenMessage },
  });

  const err = await formatError(error);

  if (err) {
    return {
      error: err,
      results: [],
    };
  }

  return {
    results: data.actions,
    prompt: data.prompt,
  };
}

export async function previewAction(
  actions: Action[],
  chosenAction: number,
  prompt: string,
  manualPrompt: Prompt,
  user: User | null
): Promise<SupabaseResponse<Executable>> {
  const { data, error } = await supabase.functions.invoke(
    "generate-action-preview",
    {
      body: { actions, chosenAction, prompt, manualPrompt, user },
    }
  );

  const err = await formatError(error);

  if (err) {
    return {
      error: err,
      results: [],
    };
  }

  return {
    results: data?.executables || [],
  };
}

export async function generateExecutablePreview(
  executable: Executable
): Promise<Preview> {
  const { data, hint } = executable;
  const preview: Preview = {};
  switch (hint) {
    case ExecutableHint.SEND_TEXT_MESSAGE:
      preview.text = typeof data === "string" ? data : JSON.stringify(data);
      break;
    case ExecutableHint.SEND_GRAPH:
      preview.media = data;
      break;
    case ExecutableHint.STORE_CARDIO_RECORDS:
    case ExecutableHint.STORE_WEEKLY_PLANS:
      const results = data as WeeklyPlan[] | CardioRecord[];
      preview.text = `${results
        .map(
          (record, i) =>
            `${i + 1}. ` +
            Object.keys(record)
              .map((key) => `${key}: ${record[key as keyof typeof record]}`)
              .join(", ")
        )
        .join("\n\n")}`;
      break;
    default:
      console.warn(
        "While generating executable preview, the default case has been chosen"
      );
      preview.text = String(data);
      break;
  }
  return preview;
}

export async function runExecutable(
  executable: Executable,
  preview: Preview,
  chat_id?: string,
  user_id?: string
): Promise<Omit<SupabaseResponse<any>, "results">> {
  const { media } = preview;
  let error: string | undefined;
  switch (executable.hint) {
    case ExecutableHint.SEND_TEXT_MESSAGE:
      if (!chat_id) return { error: "No chat selected to send the message!" };
      const { error: messageError } = await sendMessage({
        chat_id,
        sender_type: "bot",
        text: executable.data,
      });
      if (messageError) {
        error = `Error while sending the message: ${messageError}`;
        toast({
          variant: "destructive",
          title: "Failed to send the message!",
          description: messageError,
        });
      }
      break;
    case ExecutableHint.SEND_GRAPH:
      if (!chat_id) return { error: "No chat selected to send the graph!" };
      const { error: graphError } = await sendMessage({
        chat_id,
        sender_type: "bot",
        graph: media,
      });
      if (graphError) {
        error = `Error while sending the graph: ${graphError}`;
        toast({
          variant: "destructive",
          title: "Failed to send the graph!",
          description: graphError,
        });
      }
      break;
    case ExecutableHint.STORE_CARDIO_RECORDS:
      if (!user_id) return { error: "No user selected!" };
      const { error: cardioError } = await supabase
        .from("cardio_records")
        .insert(
          (executable.data as CardioRecord[]).map((item) => ({
            ...item,
            user_id,
          }))
        );
      if (cardioError) {
        return {
          error: cardioError.message,
        };
      }
      break;
    case ExecutableHint.STORE_WEEKLY_PLANS:
      if (!user_id) return { error: "No user selected!" };
      const { error: weeklyPlansError } = await supabase
        .from("weekly_plans")
        .insert(
          (executable.data as WeeklyPlan[]).map((item) => ({
            ...item,
            user_id,
          }))
        );
      if (weeklyPlansError) {
        return {
          error: weeklyPlansError.message,
        };
      }
      break;
    default:
      toast({
        variant: "destructive",
        title: "Executable hint not found!",
      });
  }
  return {
    error,
  };
}
