import Form from "@/components/messages/form/form";
import Preview from "@/components/messages/preview";

export default function Dashboard() {
  return (
    <div className="flex-1 w-full grid gap-4 md:h-[calc(100vh-200px)]">
      <Form />
      <Preview />
    </div>
  );
}
