import { toast } from "@/components/ui/use-toast";
import {
  generateExecutablePreview,
  getActions,
  previewAction,
  runExecutable,
} from "@/lib/ai-actions";
import { getMessages } from "@/lib/messages";
import { ActionsContextType } from "@/providers/actions";
import { MessageContext } from "@/providers/message";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import useStats from "./useStats";

const defaultManualPrompt: Prompt = {
  title: "",
  description: "",
};

export default function useActions(): ActionsContextType {
  const { chat, user } = useContext(MessageContext);
  const [actions, setActions] = useState<Action[]>([]);
  const [isLoading, setIsLoading] = useState({
    list: false,
    preview: false,
    execution: false,
  });
  const [chosenAction, setChosenAction] = useState<number | null>(null);
  const [preview, setPreview] = useState<Preview | null>(null);
  const [executables, setExecutables] = useState<Executable[]>([]);
  const [prompt, setPrompt] = useState<string | null>(null);
  const [manualPrompt, setManualPrompt] = useState<Prompt>(defaultManualPrompt);
  const stats = useStats(user);
  const { data: messages } = useQuery({
    queryKey: ["chat", "messages", chat?.id],
    queryFn: () => getMessages(chat!.id),
    enabled: !!chat,
  });

  async function listActions(chosenMessage?: SupabaseMessage) {
    setActions([]);
    setIsLoading((prev) => ({ ...prev, list: true }));
    const { results, prompt: _prompt } = await getActions(
      stats,
      messages
        ? messages.length <= 10
          ? messages
          : messages.slice(messages.length - 10, messages.length)
        : [],
      user,
      chosenMessage
    );
    setActions(results);
    console.log("Saved prompt: ", _prompt);
    setPrompt(_prompt);
    setIsLoading((prev) => ({ ...prev, list: false }));
  }

  const chooseAction = (index: number) => {
    setChosenAction(index);
    setPreview(null);
  };

  async function generatePreview() {
    console.log("Received prompt: ", prompt);
    console.log("Chosen action no " + chosenAction);
    if (typeof chosenAction !== "number" || !prompt) return;
    setIsLoading((prev) => ({ ...prev, preview: true }));
    try {
      const { results } = await previewAction(
        actions,
        chosenAction,
        prompt,
        manualPrompt,
        user
      );
      console.log("Received the following executables: ", results);
      for (const executable of results) {
        const output = await generateExecutablePreview(executable);
        setPreview((prev) => ({ ...prev, ...output }));
      }
      setExecutables(results);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading((prev) => ({ ...prev, preview: false }));
    }
  }

  async function executeAction() {
    if (!preview) return;
    setIsLoading((prev) => ({ ...prev, execution: true }));
    const errors: string[] = [];
    for (const executable of executables) {
      const { error } = await runExecutable(
        executable,
        preview,
        chat?.id.toString(),
        user?.id.toString()
      );
      error && errors.push(error);
    }
    if (errors.length > 0) {
      errors.forEach((err) =>
        toast({
          variant: "destructive",
          title: "Something went wrong!",
          description: err,
        })
      );
    } else {
      setChosenAction(null);
      setPreview(null);
      setActions((prev) => prev.filter((_a, i) => i !== chosenAction));
    }
    setIsLoading((prev) => ({ ...prev, execution: false }));
  }

  function reset() {
    setActions([]);
    setPreview(null);
    setChosenAction(null);
    setPrompt(null);
    setManualPrompt(defaultManualPrompt);
    setExecutables([]);
  }

  useEffect(() => {
    setActions([]);
    setPreview(null);
    setExecutables([]);
    setChosenAction(null);
    setManualPrompt({
      title: "",
      description: "",
    });
  }, [chat, user]);

  return {
    manualPrompt,
    chosenAction,
    preview,
    isLoading,
    actions,
    listActions,
    chooseAction,
    generatePreview,
    executeAction,
    reset,
    setManualPrompt,
  };
}
