import { Card } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { sendMessage } from "@/lib/messages";
import { MessageContext } from "@/providers/message";
import { FormEvent, useContext, useState } from "react";
import Media from "./media";
import Message from "./message";
import SubmitButton from "./submit";

export default function Form() {
  const [isLoading, setIsLoading] = useState(false);
  const { message, chat } = useContext(MessageContext);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    if (!chat) return;
    setIsLoading(true);
    const { error } = await sendMessage({
      chat_id: chat.id.toString(),
      ...message,
    });
    setIsLoading(false);
    if (error) {
      return toast({
        title: "Something went wrong!",
        description: error,
        variant: "destructive",
      });
    }
    return toast({
      title: "Your message has been sent!",
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card className="flex flex-col h-full gap-6">
        <div className="lg:grid grid-cols-2 flex-1">
          <Message />
          <Media />
        </div>
        <SubmitButton isLoading={isLoading} />
      </Card>
    </form>
  );
}
