import useActions from "@/hooks/useActions";
import { Dispatch, ReactNode, SetStateAction, createContext } from "react";

export type ActionsContextType = {
  actions: Action[];
  chosenAction: number | null;
  preview: Preview | null;
  manualPrompt: Prompt;
  setManualPrompt: Dispatch<SetStateAction<Prompt>>;
  listActions: (chosenMessage?: SupabaseMessage) => void;
  chooseAction: (index: number) => void;
  generatePreview: () => void;
  executeAction: () => void;
  reset: () => void;
  isLoading: {
    list: boolean;
    preview: boolean;
    execution: boolean;
  };
};

export const ActionsContext = createContext<ActionsContextType>(null!);

export default function ActionsProvider({ children }: { children: ReactNode }) {
  const actions = useActions();
  return (
    <ActionsContext.Provider value={actions}>
      {children}
    </ActionsContext.Provider>
  );
}
