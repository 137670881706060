import { useContext } from "react";
import { Card, CardContent, CardHeader } from "../ui/card";
import { MessageContext } from "@/providers/message";

export default function Preview() {
  const {
    message: { graph, image },
  } = useContext(MessageContext);
  if (!graph && !image) return;
  return (
    <Card data-testid='image-preview' className="bg-white h-full flex flex-col">
      <CardHeader className="text-xl font-medium">Preview</CardHeader>
      <CardContent className="flex-1">
        <div className="bg-slate-100 rounded-md overflow-hidden h-full flex items-center justify-center">
          <img
            data-testid='loaded-image'
            className="w-full h-full max-h-[15rem] object-contain rounded"
            src={graph || URL.createObjectURL(image!)}
            alt=""
          />
        </div>
      </CardContent>
    </Card>
  );
}
