import { useContext, useEffect, useState } from "react";
import { CardContent, CardHeader } from "../../ui/card";
import { Label } from "../../ui/label";
import { Textarea } from "../../ui/textarea";
import { BarChart2Icon, ImageIcon } from "lucide-react";
import { Button } from "../../ui/button";
import { toast } from "../../ui/use-toast";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import { Input } from "../../ui/input";
import { MessageContext } from "@/providers/message";
import { generateWeeklyGraph } from "@/lib/messages";
import MetricsGraph from "./metrics-graph";
import Ring from "@/components/ui/ring";
import useTimezone from "@/hooks/useTimezone";

type StorageInput = {
  [key: string]: string;
};

export default function Media() {
  const defaultInput = localStorage.getItem("graph-input") || "{}";
  const storageInput: StorageInput = JSON.parse(defaultInput);
  const { chat, user, setMessage } = useContext(MessageContext);
  const [input, setInput] = useState(chat ? storageInput[chat.id] || "" : "");
  const [isLoading, setIsLoading] = useState(false);
  const timeZone = useTimezone();

  async function handleGeneration(json: WeeklyGraphInput) {
    setIsLoading(true);
    const { data, error } = await generateWeeklyGraph(JSON.stringify(json));
    if (error) {
      toast({
        title: "Something went wrong!",
        description: error,
        variant: "destructive",
      });
    }
    data && setMessage((prev) => ({ ...prev, graph: data }));
    setIsLoading(false);
  }

  async function handleSubmit() {
    if (!input) {
      toast({
        title: "JSON input is required!",
        description: "Fill out the JSON input to generate a graph",
        variant: "destructive",
      });
      return;
    }
    try {
      JSON.parse(input);
    } catch (err) {
      toast({
        title: "Something went wrong!",
        description: (err as Error).message,
        variant: "destructive",
      });
      return;
    }
    const json = { ...JSON.parse(input), timeZone };
    await handleGeneration(json);
    if (!chat) return;
    const newInput = { ...storageInput, [chat.id]: input };
    localStorage.setItem("graph-input", JSON.stringify(newInput));
  }

  useEffect(() => {
    setMessage((prev) => ({ ...prev, text: "" }));
    if (!chat) return;
    setInput(storageInput[chat.id] || "");
  }, [chat, user]);

  return (
    <div className="flex flex-col">
      <CardHeader className="text-xl font-medium">Attach an image</CardHeader>
      <CardContent className="flex-1">
        <Tabs
          className="w-full gap-3 flex-1 flex flex-col h-full"
          defaultValue="json-graph"
        >
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="json-graph" className="flex items-center gap-2">
              <BarChart2Icon size={14} /> Graph with JSON
            </TabsTrigger>
            <TabsTrigger
              value="metrics-graph"
              className="flex items-center gap-2"
            >
              <BarChart2Icon size={14} /> Graph with Metrics
            </TabsTrigger>
            <TabsTrigger value="image" className="flex items-center gap-2">
              <ImageIcon size={14} /> Image
            </TabsTrigger>
          </TabsList>
          <TabsContent className="flex-1" value="json-graph">
            <div className="h-full relative">
              <div className="h-full flex flex-col gap-1.5">
                <Label htmlFor="graph-body">JSON body</Label>
                <Textarea
                  className="min-h-[16rem] flex-1"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  id="graph-body"
                  name="graph-body"
                />
              </div>
              {input && (
                <Button
                  disabled={isLoading}
                  onClick={handleSubmit}
                  size="sm"
                  type="button"
                  className="font-medium absolute bottom-2 right-4"
                >
                  {isLoading && (
                    <div className="mr-2 flex items-center">
                      <Ring />
                    </div>
                  )}
                  Generate
                </Button>
              )}
            </div>
          </TabsContent>
          <TabsContent value="metrics-graph">
            <MetricsGraph
              isLoading={isLoading}
              handleGeneration={handleGeneration}
            />
          </TabsContent>
          <TabsContent value="image">
            <Input
              className="cursor-pointer"
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={(e) => {
                const { files } = e.target;
                if (!files || !files[0]) return;
                e.target.files &&
                  setMessage((prev) => ({ ...prev, image: files[0] }));
              }}
            />
          </TabsContent>
        </Tabs>
      </CardContent>
    </div>
  );
}
