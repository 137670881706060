import { useContext } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Table,
} from "@/components/ui/table";
import Actions from "./actions";
import { useQuery } from "@tanstack/react-query";
import { getStats } from "@/lib/stats";
import { MessageContext } from "@/providers/message";
import { Skeleton } from "@/components/ui/skeleton";

type Props<T> = {
  columns: ColumnDef<T>[];
  tableName: StatsTable;
  tableTitle: string;
};

export default function StatsTable<T>({
  columns,
  tableName,
  tableTitle,
}: Props<T>) {
  const { user } = useContext(MessageContext);
  const { data, isLoading } = useQuery({
    queryKey: ["stats", tableName, user?.id],
    queryFn: () => getStats(tableName, user?.id),
    enabled: !!user,
  });

  const actionColumn: ColumnDef<any> = {
    id: "actions",
    cell: ({ row }) => <Actions row={row} tableName={tableName} key={row.id} />,
  };

  const table = useReactTable({
    data: data || [],
    columns: [...columns, actionColumn],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table title={tableTitle}>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {isLoading ? (
          <TableRow>
            {Array.from(Array(table.getAllColumns().length - 1)).map(
              (_col, k) => (
                <TableCell className="h-12 text-center" key={tableName + k}>
                  <Skeleton
                    className="h-full w-16 rounded-full"
                    key={tableName + k + "skeleton"}
                  />
                </TableCell>
              )
            )}
          </TableRow>
        ) : table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              data-state={row.getIsSelected() && "selected"}
              className=""
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
