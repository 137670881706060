import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { ColumnDef } from "@tanstack/react-table";
import StatsTable from "./table";
import Add from "./add";
import { convertTime, convertToPDT } from "@/lib/format";
import useTimezone from "@/hooks/useTimezone";
import { useMemo } from "react";

export default function CardioRecords() {
  const timezone = useTimezone();

  const columns = useMemo<(ColumnDef<CardioRecord> & ColumnProps)[]>(
    () => [
      {
        accessorKey: "start_time",
        header: "Start time",
        cell: ({ row }) => convertToPDT(row.original.start_time, timezone),
        defaultValue: "",
        required: true,
      },
      {
        accessorKey: "zone1_duration",
        header: "Zone 1",
        cell: ({ row }) => convertTime(row.original.zone1_duration),
        defaultValue: "",
        placeholder: "e.g. 30m 2s or 30 mins 2 seconds or 00:30:02",
      },
      {
        accessorKey: "zone2_duration",
        header: "Zone 2",
        cell: ({ row }) => convertTime(row.original.zone2_duration),
        defaultValue: "",
        placeholder: "e.g. 30m 2s or 30 mins 2 seconds or 00:30:02",
      },
      {
        accessorKey: "zone3_duration",
        header: "Zone 3",
        cell: ({ row }) => convertTime(row.original.zone3_duration),
        defaultValue: "",
        placeholder: "e.g. 30m 2s or 30 mins 2 seconds or 00:30:02",
      },
      {
        accessorKey: "zone4_duration",
        header: "Zone 4",
        cell: ({ row }) => convertTime(row.original.zone4_duration),
        defaultValue: "",
        placeholder: "e.g. 30m 2s or 30 mins 2 seconds or 00:30:02",
      },
      {
        accessorKey: "zone5_duration",
        header: "Zone 5",
        cell: ({ row }) => convertTime(row.original.zone5_duration),
        defaultValue: "",
        placeholder: "e.g. 30m 2s or 30 mins 2 seconds or 00:30:02",
      },
    ],
    [timezone]
  );

  return (
    <Card>
      <CardHeader className="flex-row items-center justify-between">
        <span>Cardio Records</span>
        <Add<CardioRecord>
          tableName="cardio_records"
          columns={columns}
          buttonTestId="add-cardio-record-button"
        />
      </CardHeader>
      <CardContent>
        <StatsTable
          columns={columns}
          tableName="cardio_records"
          tableTitle="Cardio Records"
        />
      </CardContent>
    </Card>
  );
}
