import { useContext } from "react";
import CardioRecords from "./cardio-records";
import Properties from "./properties";
import WeeklyPlans from "./weekly-plans";
import { MessageContext } from "@/providers/message";
import HeartZoneRanges from "./heart-zone-ranges";

export default function Stats() {
  const { user } = useContext(MessageContext);

  if (!user) return <></>;
  return (
    <div className="flex flex-col gap-4">
      <HeartZoneRanges />
      <Properties />
      <WeeklyPlans />
      <CardioRecords />
    </div>
  );
}
