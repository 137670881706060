import Stats from "../messages/stats/list";
import { Card, CardContent, CardHeader } from "./card";
import { ScrollArea, ScrollBar } from "./scroll-area";
import Dashboard from "@/pages/dashboard/dashboard";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./tabs";
import AIActions from "@/pages/dashboard/ai-actions";
import { BarChart3Icon, WandSparklesIcon, WrenchIcon } from "lucide-react";
import { useContext } from "react";
import { MessageContext } from "@/providers/message";

export default function Pages() {
  const { tab, changeTab } = useContext(MessageContext);
  const { user } = useContext(MessageContext);
  return (
    <Tabs value={tab}>
      <Card className="md:h-[calc(100vh-84px)]">
        <CardHeader>
          <TabsList className="w-full max-w-md grid grid-cols-3">
            <TabsTrigger
              value="user-context"
              onClick={() => changeTab("user-context")}
            >
              <WrenchIcon size={14} className="mr-2" /> Manual
            </TabsTrigger>
            <TabsTrigger
              value="ai-actions"
              onClick={() => changeTab("ai-actions")}
            >
              <WandSparklesIcon size={14} className="mr-2" /> AI actions
            </TabsTrigger>
            <TabsTrigger
              disabled={!user}
              value="stats"
              onClick={() => changeTab("stats")}
            >
              <BarChart3Icon size={14} className="mr-2" /> Metrics
            </TabsTrigger>
          </TabsList>
        </CardHeader>
        <CardContent className="overflow-hidden">
          <ScrollArea className="md:h-[calc(100vh-200px)] w-[calc(100vw-80px)] md:w-auto">
            <TabsContent className="mt-0" value="user-context">
              <Dashboard />
            </TabsContent>
            <TabsContent className="mt-0" value="ai-actions">
              <AIActions />
            </TabsContent>
            <TabsContent className="mt-0" value="stats">
              <Stats />
            </TabsContent>
            <ScrollBar className="md:hidden" orientation="horizontal" />
          </ScrollArea>
        </CardContent>
      </Card>
    </Tabs>
  );
}
